.heroSection {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  background-image: url(../../images/hero-m.jpg);

  @media (min-device-pixel-ratio: 2),
    (min-resolution: 192dpi),
    (min-resolution: 2dppx) {
    background-image: url(../../images/hero-m@2x.jpg);
  }

  @media screen and (min-width: 480.1px) {
    background-image: url(../../images/hero-t.jpg);

    @media (min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      background-image: url(../../images/hero-t@2x.jpg);
    }
  }

  @media screen and (min-width: 768.1px) {
    background-image: url(../../images/hero-d.jpg);

    @media (min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      background-image: url(../../images/hero-d@2x.jpg);
    }
  }

  @media screen and (min-width: 1280.1px) {
    background-image: url(../../images/hero-w.jpg);

    @media (min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      background-image: url(../../images/hero-w@2x.jpg);
    }
  }
}
