@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans"), url(./fonts/OpenSans-Italic.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local("Open Sans"),
    url(./fonts/OpenSans-SemiBoldItalic.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans"),
    url(./fonts/OpenSans-BoldItalic.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), url(./fonts/OpenSans-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans"), url(./fonts/OpenSans-SemiBold.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans"), url(./fonts/OpenSans-Bold.ttf) format("truetype");
  font-display: swap;
}

body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.4;
  font-size: 14px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
  cursor: pointer;
}
button {
  padding: 0;
  cursor: pointer;
  border: none;
}

.section-title {
  font-size: 30px;
  font-weight: 700;
  /* line-height: 140%; */
  text-transform: uppercase;

  @media screen and (min-width: 1280px) {
    font-size: 40px;
  }
}

.section-subtitle {
  font-size: 20px;
  font-weight: 400;
  /* line-height: 140%; */
  text-transform: uppercase;
}

.section-subtitle-accent {
  font-style: italic;
  font-weight: 600;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clippath: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.my-node-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.my-node-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 400ms 100ms, transform 400ms 100ms;
}
